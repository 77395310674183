@font-face {
  font-family: "BentonSans";
  src: url("bmt-fonts-web/bmt-benton-web/BentonSans-Medium.woff2")
      format("woff2"),
    url("bmt-fonts-web/bmt-benton-web/BentonSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "BentonSans";
  src: url("bmt-fonts-web/bmt-benton-web/BentonSans-Bold.woff2") format("woff2"),
    url("bmt-fonts-web/bmt-benton-web/BentonSans-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BentonSans";
  src: url("bmt-fonts-web/bmt-benton-web/BentonSans-Regular.woff2")
      format("woff2"),
    url("bmt-fonts-web/bmt-benton-web/BentonSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Darwin Pro";
  src: url("bmt-fonts-web/bmt-darwin-web/DarwinPro-Light.woff2") format("woff2"),
    url("bmt-fonts-web/bmt-darwin-web/DarwinPro-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Darwin Pro";
  src: url("bmt-fonts-web/bmt-darwin-web/DarwinPro-ExtraLight.woff2")
      format("woff2"),
    url("bmt-fonts-web/bmt-darwin-web/DarwinPro-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}


$base-font-size: 16px;
$base-line-height: 1.5;

$min_width: 320px;
$max_width: 1440px;
$min_font: $base-font-size;
$max_font: 18px;

$mod_1: 1.2; // mobile
$mod_2: 1.618; // desktop
