@import "scss/_bootstrap";
@import "scss/_fonts";
@import "scss/_mixins";

html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
  font-family: "BentonSans", sans-serif;
  color: $dark;
  min-height: 100%;
  position: relative;
  background-color: $grey;
  line-height: 1.6rem;
  padding-top: 3.5rem;
}

#top {
  position: absolute;
  top: -70px;
}

img {
  width: 100%;
  height: auto;
}

h1 {
  font-family: "Darwin Pro", sans-serif;
  font-style: normal;
  font-weight: 200;
  @include fluid-type(
    $min_width,
    $max_width,
    1.5625 * $min_font,
    2.5 * $min_font
  );
  //font-size: 3.7rem;
  // color: $dark;
  // margin-bottom: 3rem;
  margin-top: 3rem;
  letter-spacing: 1px;
  // @media (max-width: 575.98px) {
  //   margin-bottom: 1rem;
  //   //font-size: 2rem;
  // }
}

h3 {
  // margin-top: 5rem;
  margin-bottom: 2rem;
  font-size: 24px;
}

h2 {
  font-family: "Darwin Pro", sans-serif;
  font-style: normal;
  font-weight: 200;
  @include fluid-type(
    $min_width,
    $max_width,
    1.5625 * $min_font,
    2.5 * $min_font
  );
  //font-size: 3.7rem;
  // color: $dark;
  // margin-bottom: 3rem;
  margin-top: 2rem;
  letter-spacing: 1px;
  // @media (max-width: 575.98px) {
  //   margin-bottom: 1rem;
  //   //font-size: 2rem;
  // }
}

nav {
  background-color: $grey;
}

.navbar {
  background-color: $grey;
  padding-right: 0;
}

// .navbar {
//   padding-right: 8%;
// }

.navbar-brand {
  margin-right: 0;
  width: 50%;
  width: 100%;
  max-width: 14rem;
}

.navbar-collapse {
  background-color: $grey;
  padding-top: 1rem;
}

.navbar-toggler {
  border: 0;
  outline: none !important;
}

.container-fluid {
  max-width: 1440px;

  &.fixed-top {
    padding: 0;
  }
}

//emded content

.embed-container {
  position: relative;
  padding-bottom: 56.25%; /* ratio 16x9 */
  height: 0;
  overflow: hidden;
  width: 100%;
  height: auto;
}
.embed-container iframe {

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* ratio 4x3 */
.embed-container.ratio1x1 {
  padding-bottom: 100%;
}

main {
  .row {
    margin-top: 1rem;
    // align-items: center;
  }
}

main img {
  vertical-align: top;
  border-radius: 0.5rem;
  width: 100%;

  &.right {
    border-radius: 160px 0px 0px 28px;
  }

  &.left {
    border-radius: 0px 200px 28px 0px;
  }
  &.full {
    border-radius: 80px 28px 28px 0px;
  }
}

.container-fluid .row {
  margin-right: -30px;
  margin-left: -30px;
  @media (max-width: 575.98px) {
    margin-right: -15px;
    margin-left: -15px;
  }
}

.btn {
  border-radius: 28px;
  text-transform: uppercase;
  font-size: 0.8rem;
  padding: 0.6rem 2rem 0.4rem 2rem;
}

.container-fluid.col-card .col {
  display: flex;
}

//hero section

.hero {
  position: relative;
  //min-height: 80vh;
  border-bottom-right-radius: 10rem;
  border-bottom-left-radius: 1.75rem;
  overflow: hidden;
  color: $white;

  @media (min-width: 768px) {
    border-bottom-right-radius: 20rem;
  }

  &-bg {
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    
  }


  &.hero-1 {
    background-color: rgba(40,42,67,1);
  }

  &-img {

    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;

    &--gradient {
      position: absolute;
      inset: 0;
      background: linear-gradient(130deg, rgba(40,42,67,1) 40%, rgba(2,0,36,0) 96%);
  
  
      @media (min-width: 768px) {
        background: linear-gradient(150deg, rgba(40,42,67,1) 30%, rgba(2,0,36,0) 76%);
      }
    }
  }

  // &-content {
  //   top: 5vw;
  // }

  & > .container-fluid {
    //height: 100%;

    & > .row {
      //height: 100%;
    }
  }

  & h2 {
    font-size: 2rem;
    font-weight: 300;
  }
}

.white {
  background: $white;
}

main .full-card-outer {
  padding-bottom: 100%;
  position: relative;
}
main .full-card {
  position: absolute;
  background: $orange;
  width: 100%;
  left: 2rem;
  border-radius: 0 80px 0 80px;
  padding: 2rem;
  top: 20%;
  color: $white;
  & h2 {
    font-family: "Darwin Pro";
    margin-top: 0;
  }
}

.col-card .card {
  display: flex;
  justify-content: space-between;
  background: $white;
  box-shadow: 0px 0px 0px #1c23350d;
  padding: 2rem;
  margin-bottom: 1.5rem;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:hover{
    box-shadow: 0px 5px 15px #1c233534;
  }

  &.left {
    border-radius: 80px 28px 28px 28px;
  }
  &.middle {
    border-radius: 28px;
    h3 {
      color: $orange;
    }
  }
  &.right {
    border-radius: 28px 80px 28px 28px;
  }

  h3 {
    font-family: "Darwin Pro", sans-serif;
    font-style: normal;
    font-weight: 200;
    margin-top: 1rem;
  }
}

.schritte {
  display: flex;
  flex-direction: column;
  & img {
    width: 40px;
  }
  & .schritte-grid {
    display: grid;
    grid-template-columns: 4rem auto;
    grid-template-rows: auto auto;
    grid-gap: 0.75rem;
    & .number {
      position: relative;
      grid-row: 1 / span 2;
      & span {
        display: inline-block;
        background: $orange;
        padding: 0.3rem;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        text-align: center;
        color: $white;
      }
    }
  }
}

.col-glasses {
  max-width: none;
  overflow: hidden;
}

.slider-glasses {
  justify-content: center;

  .slick-dots {
    bottom: -2rem;
  }

  .col-3.slick-slide {
    min-width: 285px;
  }
}

//.slick-dots {
//}

.slick-dotted.slick-slider {
  overflow: visible !important;
}

.col-glasses .card {
  display: flex;
  flex-direction: row;
  border-radius: 1em;
  //border-color: $dark;
  border: none;

  & .slider-glasses {
    overflow: auto;
  }

  & > img {
    width: 40%;
    height: auto;
    border-radius: 1em 0 0 1em;
    //border-right: 1px solid $dark;
  }

  & article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex: 1;

    & img {
      width: 80%;
    }
    & h4 {
      font-family: "Darwin Pro", sans-serif;
      font-style: bold;
      font-weight: 700;
      font-size: 1rem;
    }
  }

  &.middle {
    border-radius: 28px;
    box-shadow: 10px 15px 10px #1c233534;
    h3 {
      color: $orange;
    }
  }
  &.right {
    border-radius: 28px 80px 28px 28px;
  }

  h3 {
    font-family: "Darwin Pro", sans-serif;
    font-style: normal;
    font-weight: 200;
    margin-top: 1rem;
  }
}

.slider-cards {
  .slick-list {
    //overflow: visible;
  }
  .slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}
}

.gutschein {
  background: $white;
  border-radius: 8rem 0 8rem 0;
  & svg {
    width: 80%;
    height: auto;
  }
  & .container-fluid {
    width: 100%;
    max-width: 100%;
  }
}

p.grid {
  display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
}

footer {
  margin-left: 2rem;
  padding-bottom: 1rem;
  padding-top: 4rem;
  background: $beige;
  border-radius: 50px 0 0 0;
  h3{font-size: 1.25rem;}
  & .grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
  & .links {
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    border-radius: 0.5rem;
     a {font-weight: 500;}
  }
   & a {
      color: $dark;
    }
}

.cc-revoke, .cc-window {
    z-index: 1000;
}

.cc-color-override-588740840 .cc-btn {
    outline: none;
    border-radius: 20px;
    font-weight: normal;
}
.cc-color-override-588740840 .cc-btn:hover, .cc-color-override-588740840 .cc-btn:focus {
    background-color: $orange !important;
    color: #fff;
}

@media (max-width: 320px) {
  main .full-card-outer {
    padding-bottom: 160%;
  }
}

@media (min-width: 375px) {
  main .full-card-outer {
    padding-bottom: 100%;
  }
}

@media (min-width: 568px) {
  main .full-card-outer {
    padding-bottom: 30%;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 2rem;
  }
}

@media (min-width: 768px) {

  h2 {
    margin-top: 3rem;
  }

  .navbar {
    padding-right: 15px;
  }

  .navbar-brand {
    //width: 23%;
    // margin-left: 15px;
    & > img {
      width: 100%;
    }
  }
  main .full-card-outer {
    padding-bottom: 4rem;
    padding-left: 11.111111%;
  }
  main .full-card {
    position: absolute;
    padding: 3rem;
    bottom: auto;
    top: 8.7%;
    // height: 100%;
    width: 100%;
    width: max-content;
    left: 0;
    border-radius: 0 200px 0 200px;
  }

  main img {
    &.full {
      border-radius: 28px 28px 200px 0px;
    }
  }

  .schritte {
    flex-direction: row;
    & img {
      width: 50px;
    }
    & .schritte-grid {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto;
      grid-gap: 1.25rem;

      & > p {
        padding: 0.7rem;
        padding-top:0;
      }
      & div {
        text-align: center;
      }
      & .number {
        grid-row: auto;
        //& span {

        //}
      }
    }
  }

  .number.full::after {
    content: " ";
    position: absolute;
    top: 17px;
    width: 110%;
    left: 0;
    border-bottom: 2px solid $orange;
    z-index: -1;
  }
  .number.first::after {
    content: " ";
    position: absolute;
    top: 17px;
    width: 70%;
    left: 50%;
    border-bottom: 2px solid $orange;
    z-index: -1;
  }
  .number.last::after {
    content: " ";
    position: absolute;
    top: 17px;
    width: 70%;
    right: 50%;
    border-bottom: 2px solid $orange;
    z-index: -1;
  }
}


@media (min-width: 1024px) {

  main .full-card-outer {
    padding-bottom: 2rem;
  }

  main .full-card {
    position: absolute;
    padding: 5;
    bottom: auto;
    top: 8.7%;
    // height: 100%;
    width: 50%;
    left: 0;
    border-radius: 0 200px 0 200px;
  }
}
